<!-- @format -->

<template>
	<div>
		<LayoutDefaultNew>
			<div v-if="isCreateDocumentVisible">
				<CreateDocument :key="refreshKeyCreateDocument" />
			</div>
		</LayoutDefaultNew>
	</div>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import CreateDocument from '@/_srcv2/pages/admin/create-document/CreateDocument.vue'
import useCreateDocumentComponentState from '@/_srcv2/pages/admin/create-document/components/useCreateDocumentComponentsState.js'

export default {
	name: 'CreateOrder',
	components: {
		CreateDocument,
	},
	setup() {
		const { isCreateDocumentVisible, refreshKeyCreateDocument, createLevel } =
			useCreateDocumentComponentState()
		onMounted(() => {
			console.log('CreateOrderDispatch is initialized')
			createLevel.value = 1
			refreshKeyCreateDocument.value += 1
			isCreateDocumentVisible.value = false
			setTimeout(() => {
				isCreateDocumentVisible.value = true
			}, 500)
		})
		// todo -----------------------------------------------------------------
		return {
			createLevel,
			isCreateDocumentVisible,
			refreshKeyCreateDocument,
		}
	},
}
</script>

<style lang="scss" scoped></style>
